a{
    font-size: small !important;
    font-weight: normal !important;
    text-decoration: none;
    color: white;
}
a:hover{
    color: #57dca8 !important;
    text-decoration: none;
}
.pbutton{
    background-color: white;
    
    .pbutton1{
        color: black;
        font-weight: bolder;
    }
    &:hover{
        background-color: #57dca8;
        .pbutton1{
            color: white;
        }
    }
}

